export const EVENTS = {
    ACCOUNT_CUSTOM: 'account_custom',
    CLICK_CUSTOM: 'click_custom',
    SCROLL_CUSTOM: 'scroll_custom',
    SEARCH_CUSTOM: 'search_custom',
    VIEW_CUSTOM: 'view_custom',
};

export const ACCOUNT_ACTIONS = {
    LOGIN: 'login',
    ERROR_LOGIN: 'error_login',
    FORGET_PASSWORD: 'forget_password',
    LOGOUT: 'logout',
    ACCOUNT_CREATION: 'account_creation',
};

export const CLICK_TYPES = {
    CLICK_TICKET: 'click_ticket',
    OPEN_TOAST: 'open_toast',
    SHARE: 'share',
    CLICK_ALREADY_DONE: 'click_already_done',
    SAVE: 'save',
    LIKE: 'like',
    UNSAVE: 'unsave',
    UNLIKE: 'unlike',
    UNDONE: 'undone',
    CLICK_EXPLORATION: 'click_exploration',
    CLICK_VOYAGE: 'click_voyage',
    CLICK_MY_SPACE: 'click_my_space',
};
